import { JSXElementConstructor, ReactElement } from "react"
import { ActionKey } from "../../../../enums/ActionKey"
import ComputeAlternativesDataInput from "./ComputeAlternatives"
import { JsxElement } from "typescript"

export type ActionInputComponent = (props: {state:any, setState: (a:any) => void}) => JSX.Element | null

// Create an ActionMap
const ActionInputComponentMap: Record<ActionKey, ActionInputComponent | null> = {
    [ActionKey.ValidateBookingConfirmation]: null,
    [ActionKey.ValidateSignature]: null,
    [ActionKey.ValidateCaseClaim]: null,
    [ActionKey.GenerateAirlineEmail]: null,
    [ActionKey.GenerateSubpoena]: null,
    [ActionKey.SendConfirmationEmail]: null,
    [ActionKey.DecorateCase]: null,
    [ActionKey.PullDataFromBookingConfirmation]: null,
    [ActionKey.GenerateTransferAgreement]: null,
    [ActionKey.ComputeAltRoutes]: ({state, setState}) => ComputeAlternativesDataInput({state, setState}),
    [ActionKey.GeneratePowerOfAttorney]: null,
}

export default ActionInputComponentMap
